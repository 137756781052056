<template>
    <div class="navBox">
        <div class="itemList">
            <div :class="['item',currentIndex == index?'itemActive':'']" v-for="(item,index) in navTabbar" :key="index" @click="tiIndex(index)">{{item.name}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        navTabbar:{
            type:Array,
            default:()=>[]
        }
    },
    data(){
        return{
            currentIndex:0
        }
    },
    methods:{
        //切换下标
        tiIndex(index){
            this.currentIndex = index;
            this.$emit('tabIndex',index)
        }
    }
}
</script>

<style scoped lang="less">
.navBox{
    width: 1200px;
    height: 66px;
    background: #fff;
    padding-left: 37px;
    margin-bottom: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #f7f7f7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .itemList{
        height: 28rpx;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        .item{
            font-size: 28rpx;
            font-weight: bold;
            line-height: 24rpx;
            color: #333333;
            margin-right: 30px;
            cursor: pointer;
        }
        .itemActive{
            color:  #097638;
        }
    }
}
</style>