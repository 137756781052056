<template>
    <div>
        <commonHeader :current-index="0"></commonHeader>
        <div class="tabItems">
            <div class="item">
               <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item v-for="(item,index) in routerArr" :to="item.path" :key="index">{{item.title}}</el-breadcrumb-item>
                    <!-- <el-breadcrumb-item :to="{ path: '/' }">活动管理</el-breadcrumb-item> -->
                </el-breadcrumb>
            </div>
        </div>
        <div class="contation">
            <div class="conItem">
                <navHeader :navTabbar="navTabbar" @tabIndex="tabbarIndex"></navHeader>
                <newList :title="navTabbar[index].name" :newArr="newArr"></newList>
                <div class="pageItem">
                    <paging ref="pagChild" :total="total" :rows="rows" @clickPage="pageList"></paging> 
                </div>
            </div>
        </div>
        <commonFooter></commonFooter>
    </div>
</template>

<script>
import { newCategory,getNewsList } from '@/common/public.js'
import commonHeader from '@/components/commonHeader'
import navHeader from '@/components/news/newHeader'
import newList from '@/components/news/newList'
import paging from '../../components/course/paging'
import commonFooter from '@/components/commonFooter'
export default {
    components:{
        commonHeader,commonFooter,paging,navHeader,newList
    },
    data(){
        return{
            routerArr:[
                {title:'首页',path:'/index'},
                {title:'文章',path:''}],
            navTabbar:[],
            index:0,
            total:200,
            rows:10,
            page:1,
            categoryId:'',
            newArr:[]
        }
    },
    created(){
        this.getChildItem()
    },
    methods:{
        //去首页
        toIndex(index){
            console.log(index,'indexindexindex')
            // if(index !== 0) return
            // this.$router.push({
            //     path:'/'
            // }) 
        },
        //获取分类接口
        getChildItem(){
            newCategory().then(res=>{
                console.log(res,'获取分类接口')
                this.navTabbar = res.result.mapList;
                this.categoryId = res.result.mapList[0].id;
                this.getLists()
            })
        },
        //切换了导航index
        tabbarIndex(index){
            this.index = index;
            this.page = 1;
            this.categoryId = this.navTabbar[index].id
            this.getLists()
            this.$refs.pagChild.goBackPage()
        },
        //分类列表
        getLists(){
            let data = {
                categoryId:this.categoryId,
                page:this.page,
                rows:10
            };
            getNewsList(data).then(res=>{
                console.log(res)
                this.newArr = res.result.mapList;
                this.total = res.result.listSize;
            })
        },
        //切换页数
        pageList(index){
            this.page = index;
            this.getLists()
            console.log(index)
        }
    }
}
</script>

<style scoped lang="less">
.tabItems{
    width: 100%;
    border-top: 1px solid #eee;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .item{
        width: 1200px;
        height: 56px;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
.contation{
    width: 100%;
    padding: 32px 0;
    background: #f7f7f7;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .conItem{
        width: 1200px;
        .pageItem{
            width:1200px;
            padding: 20px 0;
        }
    }
}
</style>