<template>
    <div class="newsList">
        <div class="title">{{title}}</div>
        <div class="listBox">
            <div class="item" v-for="(item,index) in newArr" :key="index" @click="toNewDetail(item.id)">
                <div class="left">
                    <img :src="item.defaultImage" alt="">
                </div>
                <div class="right">
                    <div class="tit">{{item.title}}</div>
                    <div class="text">{{item.represent}}</div>
                    <div class="time">{{item.createTime | toTime(item.createTime)}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        newArr:{
            type:Array,
            default:()=>[]
        },
        title:{
            type:String,
            default:''
        }
    },
    data(){
        return{

        }
    },
    methods:{
        //跳转到详情页面
        toNewDetail(id){
            this.$router.push({
                path:'/newsDetail',
                query:{id}
            })
        }
    }
}
</script>

<style scoped lang="less">
.newsList{
    width: 1200px;
    background: #fff;
    .title{
        width: 100%;
        padding: 0 33px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 20px;
        font-weight: bold;
        line-height: 60px;
        color: #333333;
        border-bottom:  2px solid #eee;
    }
    .listBox{
        width: 100%;
        padding: 0 33px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .item{
           width: 100%;
           height: 147px;
           display: flex;
           flex-direction: row;
           align-items: center;
           border-bottom: 1px dashed #eee;
           cursor: pointer;
           .left{
                width: 200px;
                height: 112px;
                margin-right: 20px;
               img{
                    width: 200px;
                    height: 112px;
                    border-radius: 6px;
               }
           }
           .right{
               width: 879px;
               height: 112px;
               display: flex;
               flex-direction: column;
               justify-content: space-between;
               .tit{
                font-size: 18px;
                font-weight: 400;
                line-height: 25px;
                color: #333333;
               }
               .text{
                    width: 100%;
                    height: 38px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: #666666;
                    display: -webkit-box;
					overflow: hidden;
					white-space: normal !important;
					text-overflow: ellipsis;
					word-wrap: break-word;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
               }
               .time{
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 17px;
                    padding: 2px 0 4px 0;
                    color: #999999;
               }
           } 
        }
    }
}
</style>